@use "../../global/constants.scss";

.mainContainer {
  margin-top: 3rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 2rem;
  height: 100%;
  background-color: constants.$primary-black;
  span {
    letter-spacing: 1px;
    color: rgba($color: white, $alpha: 0.4);
  }
  em {
    margin-top: 2rem;
    text-align: center;
  }
}

.footerContainer {
  width: 75%;

  justify-content: space-between;
  display: flex;
  flex-direction: row;
}

.newsletter {
  width: 270px;
}

.heading {
  font-size: 0.8rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 1rem;
}
.pages {
  display: flex;
  flex-direction: column;
}
.text {
  display: block;
  cursor: pointer;
  width: 100%;
  font-size: 0.8rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0.5rem;
  transition: 0.3s;
  &:hover {
    color: rgba(255, 255, 255, 0.9);
  }
}

@media (max-width: 1050px) {
  .footerContainer {
    width: 90%;
  }
}
@media (max-width: 800px) {
  .footerContainer {
    width: 100%;
  }
  .newsletter {
    width: 200px;
  }
}

@media (max-width: 655px) {
  .mainContainer {
    padding: 1rem;
  }
}

@media (max-width: 600px) {
  .mainContainer {
    justify-content: center;
  }
  .footerContainer {
    justify-content: center;
  }
  .newsletter {
    width: 80%;
  }
}

.mainCard {
  background-color: white;
  height: 50px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding: 10px 2rem;
  box-shadow: 0px 4px 10px rgba($color: #000000, $alpha: 0.1);
  user-select: none;
}

.country,
.name,
.type,
.actions {
  flex: 1;
  font-size: 0.85rem;
}

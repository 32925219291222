@use "./constants.scss";
@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

html {
  height: 100%;
}
body {
  color: constants.$primary-black;
  height: 100%;
  // min-height: 800px;
  margin: 0;
  font-family: "Rubik", sans-serif;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  overflow-x: hidden;
  height: 100vh;
}
.App {
  margin: auto;
  max-width: 2560px;
  min-width: 320px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

main {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}

a {
  color: constants.$primary-black;
  text-decoration: none;
  font-weight: 600;
  font-size: 1rem;
  &:hover {
    color: inherit;
  }
}

h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 2.1875rem;
  font-weight: 500;
}

span {
  font-size: 0.9rem;
  letter-spacing: 1px;
  font-weight: 500;
}

p {
  font-size: 0.9rem;
  margin: 0;
  padding: 0;
}

em {
  font-size: 0.8rem;
  color: grey;
  font-weight: 400;
}

img,
a {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

#mainContainer {
  padding: 2rem 3rem;
  margin: 0;
  gap: 2rem;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 850px) and (min-width: 680px) {
  p {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 2rem;
  }
  span {
    font-size: 0.8rem;
  }
}
@media (max-width: 400px) {
  span {
    font-size: 0.7rem;
  }
}

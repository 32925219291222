@use '../../global/constants.scss';
#main {
  a {
    color: white;
    text-decoration: none;
  }
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  align-items: center;

  h3 {
    font-size: 2rem;
    font-weight: bold;
  }
  hr {
    height: 2px;
    width: 80%;
    background-color: black;
  }
}
.divider {
  position: relative;
  width: 2px;
  background-color: #373737;
  height: 60%;
  bottom: 0;
}
.main_container {
  position: relative;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.social_container {
  display: flex;
  gap: 1rem;
  margin: 1.5rem 0;
}

.social_link {
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  // background-color: constants.$primary-color;/
  padding: 0.4rem;
  border-radius: 6px;
  transition: 0.2s;
  &:hover {
    background-color: rgb(212, 212, 212);
  }
}

.contact {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 2rem;
  width: 100%;
  padding: 0 5rem;
  gap: 1rem;
}
.contact_n {
  font-size: 1rem;
  margin-bottom: 1rem;
}
#form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3rem;
}
.textarea {
  border-radius: 6px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  resize: none;
  height: 200px;
  border-radius: 6px;
  padding: 0.5rem 0.5rem;
  background: white;
  border: 1px solid #373737;
  font-size: 0.8rem;
  font-weight: 400;
  &:focus {
    outline: none;
  }
}

.textareaError {
  border: 2px solid red;
  @extend .textarea;
}
.button {
  width: 200px;
  background-color: constants.$primary-color;
}

.main_loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.loading_header {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.mainCard {
  position: relative;
  background-color: white;
  height: 50px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding: 10px 2rem;
  box-shadow: 0px 4px 10px rgba($color: #000000, $alpha: 0.1);
  user-select: none;
  div {
    flex: 1;
  }
}

.image {
  height: 40px;
  width: 40px;
  object-fit: contain;
}

.action {
  align-items: center;
  display: flex;
  gap: 1rem;
}

.non_flex {
  flex: 0 !important;
  cursor: pointer;
}

.discount {
  @extend .non_flex;
  cursor: pointer;

  position: relative;
}
.input {
  width: 100px;
  outline: none;
  border: none;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.2rem 0.4rem;
  background-color: white;
  box-shadow: -4px -2px 10px rgba($color: #000000, $alpha: 0.1);
  border-radius: 8px;
  position: absolute;
}

.buttons {
  display: flex;
  gap: 1.5rem;
  margin-top: 1rem;
}

.text_button {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  color: #2b79c2;
  width: fit-content;
  // margin: 1rem 0 0 0;
  padding: 0;
  &::after &::before {
    pointer-events: none;
  }
}

.cancel {
  @extend .text_button;
  color: red;
}

.header {
  display: flex;
  flex-direction: row;
  //   justify-content: space-between;
  margin-bottom: 10px;
  margin-left: 10px;
  p {
    flex: 1;
    font-size: 16px;
    font-weight: 500;
  }
}
.offerCard {
  border-radius: 8px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  margin-bottom: 15px;

  .imageContainer {
    height: 40px;
    width: 40px;
    background-color: red;
  }
  div {
    flex: 1;
  }
}
.layer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layerBg {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}

.cardContainer {
  width: 400px;
  // height: 200px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  z-index: 9999;
  > p {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
  }
}
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.icons {
  display: flex;
  gap: 20px;

  align-items: center;
}

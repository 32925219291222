.item_card {
  gap: 1rem;
  display: flex;
}

.image_container {
  width: 100px;
  height: 100px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.flex {
  flex: 1;
  display: flex;
  align-items: center;

  justify-content: center;
}

.item_title {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.8rem;
  font-weight: 400;
  color: #0070c9;
  transition: 0.3s;
  text-decoration: underline;
  &:hover {
    color: #2d8ede;
  }
}
.qt_selector {
  display: flex;
}
.qt_button {
  cursor: pointer;
  padding: 0.3rem;
  border: 1px solid rgb(107, 107, 107);
  user-select: none;
}
.add {
  border-left: none;
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;
}
.remove {
  border-right: none;
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;
}
.qt_container {
  width: 50px;
  padding: 0.3rem 1rem;
  border: 1px solid rgb(172, 172, 172);
  text-align: center;
}

.divider {
  border-bottom: 1px solid rgb(248, 248, 248);
  margin: 0;
  width: 100%;
}

@use "../../../global/constants.scss";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn {
  height: 40px;
  background-color: constants.$admin-main-color;
  border: none;
  transition: 0.2s ease-in;
  &:hover {
    background-color: #141f80;
  }
}

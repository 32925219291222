@use "../../../../global/constants.scss";

.news_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  .news_title {
    flex: 1;
    h3 {
      margin: 0;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .news_date {
    flex: 1;
    p {
      font-size: 14px;
      color: gray;
    }
  }
  .news_btn {
    display: flex;
    gap: 10px;
    p {
      cursor: pointer;
      color: constants.$admin-main-color;
    }
  }
}

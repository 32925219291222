@use "../../global/constants.scss";

header {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header_top {
  height: 30px;
  background-color: constants.$primary-color;
  display: flex;
  justify-content: space-between;
}
.part {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0 1rem;
  color: white;
  font-size: 0.8rem;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s ease;
}

.mainHeader {
  padding: 0 1rem;
  display: flex;
  flex-direction: row;
  height: 80px;
  width: 100%;
}

.logo {
  flex: 1;
  display: flex;
  align-self: center;
  justify-content: center;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  img {
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    width: 150px;
  }
}

.navBar_pages {
  flex: 2;
}

.navBar_pagesList {
  width: max-content;
  display: flex;
  flex-direction: row;
  margin: auto;
  padding: 0;
  align-items: center;
  height: 100%;
}

.navBar_item {
  margin: 0 25px;
  list-style: none;
  position: relative;
  user-select: none;
  &:hover::before {
    width: 25px;
  }
}

.navBar_item::before {
  content: "";
  height: 3px;
  width: 0;
  background: black;
  position: absolute;
  bottom: -2px;
  left: 1px;
  background-clip: padding-box;
  border-radius: 100px;
  transition: width 0.2s ease-in-out;
}

.navBar_auth {
  flex: 1;
  display: flex;
  align-items: center;
  margin-right: 20px;
  justify-content: center;
  gap: 1.4rem;
}

.sign_in_text {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  color: #000;

  transition: 0.3s;
  cursor: pointer;
  &:hover {
    color: #000000a7;
  }
}
.sign_button {
  border: 1px solid rgb(0, 0, 0);
  padding: 4px 8px;
  border-radius: 6px;
  transition: 0.3s;
  &:hover {
    border-color: #000000a7;
    p {
      color: #000000a7;
    }
  }
  p {
    @extend .sign_in_text;
  }
}

.input {
  opacity: 1;
  width: 50%;
  transition: 0.2s ease-in-out;
}

.navBar_products {
  position: relative;
  width: 100%;
  height: 45px;
  background-color: constants.$primary-color;
  transition: 0.2s ease-in-out;
  display: flex;
}

.navBar_productsList {
  cursor: pointer;
  flex: 1;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0;
  align-items: center;
  // margin: auto;
  width: max-content;
}

.cart_icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  fill: white;
  &:hover .cart_main {
    display: block;
  }
}
.cart_size {
  height: 100%;
  width: 100%;
  top: 5px;
  left: 1px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;

  span {
    font-size: 0.8rem;
    font-weight: 400;
    color: #184e92;
  }
}
.cart_main {
  display: none;
}

@media (max-width: 1000px) {
  .logo {
    justify-content: flex-start;
    margin-left: 20px;
    img {
      width: 140px;
    }
  }
  .navBar_auth {
    justify-content: flex-end;
  }
  .input {
    opacity: 0;
    // display: none;
  }
}

@media (max-width: 850px) {
  .navBar_products {
    height: 20px;
  }
}

@media (max-width: 560px) {
  .sign_in_text {
    font-size: 0.8rem;
  }
  .sign_button {
    padding: 0.1rem 0.3rem;
  }
}

@media (max-width: 480px) {
  .input {
    display: none;
  }
  .sign_button {
    p {
      display: block !important;
    }
    padding: 0;
    border: none;
  }
  .sign_in_text {
    display: none;
  }
  .logo {
    img {
      width: 100px;
    }
  }
}

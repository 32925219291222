.imageMain {
  display: flex;

  width: 100%;
}

.side_images_main {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 500px;
  overflow-y: scroll;
  flex: 1;
  display: grid;
  align-items: flex-start;

  gap: 0.5rem;
}

.side_image_container {
  position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  border-radius: 1rem;
  border: 1px solid #484848;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    position: absolute;
    top: 0;
    height: 100%;
    width: inherit;
    object-fit: cover;
  }
}

@media (max-width: 1050px) {
  .side_images_main {
    height: 300px;
  }
}

@media (min-width: 1050px) {
  .side_image_container {
    padding: 0;
    height: 80px;
    width: 80px;
  }
}
.blur {
  top: 0;
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  transition: 0.3s;
  &:hover {
    background-color: rgba(0, 0, 0, 0.198);
  }
  border-radius: 1rem;
}
.selected_image {
  position: relative;
  display: flex;
  flex: 5;
  display: flex;
  justify-content: center;
}
.image {
  height: 500px;
  width: inherit;
  max-width: 90%;
  object-fit: contain;
  border-radius: 1rem;
}

@media (max-width: 1050px) {
  .image {
    height: 300px;
    width: 300px;
  }
}

.remove {
  z-index: 999;
  position: absolute;
  top: 0;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.756);
  right: 0;
  border-radius: 60px;
  transition: 0.1s;
  &:hover {
    background-color: white;
  }
}

.out_of_stock {
  right: 0;
  position: absolute;
}

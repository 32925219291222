@use "../../../../global/constants.scss";

.notFound {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #999;
}

.cardList {
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
}

#footer {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.mainC {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
}

.f_pa_di {
  font-size: 0.8rem;
  font-weight: 600;
}

.btns {
  display: flex;
  gap: 1rem;
}

.button {
  cursor: pointer;
  outline: none;
  border: none;
  padding: 0.4rem 0.9rem;
  color: white;
  border-radius: 8px;
  transition: 0.3s;
  &:hover {
    opacity: 0.8;
  }
}

.next {
  @extend .button;
  background-color: constants.$admin-main-color;
}

.prev {
  @extend .button;
  background-color: constants.$secondary-color;
}

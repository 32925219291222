.width {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  background-color: transparent !important;
}
.inputs {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  resize: none;
  height: 40px;
  border-radius: 6px;
  padding: 0.3rem 0.5rem;
  background: white;
  border: 1px solid #373737;
  input {
    width: 100%;
    height: auto;
    resize: none;
    border: none;
    font-size: 0.8rem;
    font-weight: 400;
    &:focus {
      outline: none;
    }
  }
}

.L_Input {
  @extend .inputs;
  input {
    margin-left: 9px;
  }
}

.error {
  @extend .inputs;
  border-color: red;
}

.mail_chimp {
  div {
    display: flex;
    flex-direction: column;
    button {
      margin-top: 1rem;
      border: none;
      background-color: transparent;
      cursor: pointer;
      font-size: 0.7rem;
      font-weight: 400;
      color: rgb(67, 123, 233);
      text-decoration: underline;
      transition: 0.3s;
      text-align: end;
      &:hover {
        color: rgb(100, 145, 235);
      }
    }
  }

  input {
    outline: none;
    @extend .inputs;
  }
}

// Policy.module.scss

// Styling for the main container
#main_container {
    padding: 2rem;
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;

    h1 {
        font-size: 2rem;
        font-weight: bold;
        color: #333;
        margin-bottom: 1.5rem;
    }

    h2 {
        font-size: 1.5rem;
        color: #444;
        margin-top: 2rem;
        margin-bottom: 0.75rem;
    }

    p {
        font-size: 1rem;
        line-height: 1.6;
        color: #666;
        margin-bottom: 1.25rem;
    }

    ul {
        list-style-type: disc;
        margin-left: 2rem;
        margin-bottom: 1.5rem;

        li {
            font-size: 1rem;
            line-height: 1.6;
            color: #666;
            margin-bottom: 0.75rem;
        }
    }

    // Specific styling for certain items like non-returnable list
    ul li {
        &::marker {
            color: #007bff; // Custom bullet color
        }
    }

    // Responsive design
    @media (max-width: 768px) {
        padding: 1rem;

        h1 {
            font-size: 1.75rem;
        }

        h2 {
            font-size: 1.25rem;
        }

        p,
        li {
            font-size: 0.9rem;
        }
    }

    @media (max-width: 480px) {
        padding: 0.5rem;

        h1 {
            font-size: 1.5rem;
        }

        h2 {
            font-size: 1.1rem;
        }

        p,
        li {
            font-size: 0.85rem;
        }
    }
}

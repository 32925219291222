.mainContainer {
  padding: 1.5rem 1rem;
  // position: relative;
  flex: 1;
}
.header {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  .header_text {
    font-size: 2rem;
    width: max-content;
    margin: 0 25px;
    list-style: none;
    position: relative;
    user-select: none;
  }
}

.sort_container {
  cursor: pointer;
  padding: 0.5rem 1rem;
  display: flex;
  color: rgb(113, 113, 113);
  font-weight: 500;
  // margin: 0 25px;
  font-size: small;
  align-items: center;
  gap: 1rem;
  position: relative;
  border: 2px solid rgb(113, 113, 113);
  border-radius: 16px;
  font-family: "rubik", sans-serif;
  -webkit-appearance: none;
  outline: none;
}
.no_border {
  border: none;
}
.sort_options {
  display: flex;
  align-items: center;
  flex-direction: row;
}

@media (max-width: 580px) {
  .sort_options {
    display: flex;
    flex-direction: column;
  }
  .header {
    display: flex;
    flex-direction: column;
  }
}

@use "../../global/constants.scss";

.textContainer {
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dropDown_menu {
  display: none;

  width: 100%;
  background-color: white;
  position: absolute;
  transition: 0.2s ease;
  z-index: 9999;
  border-radius: 6px;
  box-shadow: 4px 0 12px rgba(0, 0, 0, 0.431);
  transition: 0.2s;
}

.dropDown_menu_list {
  list-style: none;
  padding: 1rem 0;
  width: max-content;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.dropDown_menu_listItem {
  font-size: 0.9rem;
  font-weight: 500;
  a {
    width: inherit;
    height: inherit;
  }
}

.listItem {
  .title {
    color: white;
    opacity: 0.8;
    &:hover {
      color: white;
      opacity: 1;
    }
  }
  p {
    color: constants.$primary-black;
    opacity: 0.8;
    transition: 0.1s ease-in;
    cursor: pointer;
    &:hover {
      color: black;
    }
  }
  height: 100%;
  flex: 1;
  align-items: center;
  user-select: none;
  position: relative;

  color: rgb(230, 230, 230);
  font-size: 0.8rem;
  font-weight: 600;
  transition: 0.3s ease;
  &:hover {
    color: white;
  }
  &::before {
    border: solid transparent;
    border-top-color: transparent;
    border-top-width: medium;
    border-right-color: transparent;
    border-right-width: medium;
    border-bottom-color: transparent;
    border-bottom-width: medium;
    border-left-color: transparent;
    border-left-width: medium;
    content: "";
    height: 0;
    left: 50%;
    top: 70%;
    opacity: 1;
    pointer-events: none;
    position: absolute;
    width: 0;
    // z-index: 100000;
  }
  &:hover::before {
    border-color: hsla(0, 0%, 87%, 0) hsla(0, 0%, 87%, 0) constants.$secondary-color;
    // bottom: -15px;
    border-width: 8px;
    margin-left: -8px;
    border-radius: 50px;
  }

  &:hover .dropDown_menu {
    display: block;
  }
}

@use '../../../global/constants.scss';

.store_location {
  position: relative;
  margin-top: 40px;
  width: 100%;
}

.store_image {
  height: 500px;
  overflow: hidden;
  width: 100%;
  background-color: #999999;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.store_name {
  text-align: center;
  font-size: 2rem;
  font-weight: 500;
  margin-top: 2rem;
}

.store_info {
  display: flex;
  flex-direction: row;
  gap: 4rem;
  margin-top: 2rem;
}

.store_address {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
  text-align: center;
  .contact_info_item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  div {
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-weight: 400;
  }
  .hours {
    margin: 1rem 0;
  }
}

.store_contactInfo {
  @extend .store_address;
  align-items: flex-start;
}

.directions_button {
  cursor: pointer;
  margin-top: 1.5rem;
  padding: 0.5rem 1.2rem;
  border-radius: 6px;
  background-color: constants.$primary-color;
  color: white;
  font-weight: 600;
  font-size: 1rem;
  transition: 0.2s;
  &:hover {
    background-color: constants.$secondary-color;
  }
}

.brands_container {
  width: 100%;
  height: auto;
  padding: 0 0.5rem;
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 0.2rem;
}

.larger {
  @extend .brands_container;
  grid-template-columns: auto auto auto auto auto auto auto;
}

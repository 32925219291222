.main {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
}
.body {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2.5rem;
  gap: 1rem;
}
.first {
  max-width: 700px;
  width: 50%;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.description {
  padding: 4rem 1.5rem;
  h3 {
    font-size: 1.5rem;
  }
  p {
    text-align: justify;
    padding-left: 0.2rem;
  }
}
.second {
  max-width: 700px;

  width: 50%;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.prices {
  color: #494949;
  font-weight: 500;
  margin: 0;
}

.header {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.multilineInput {
  width: 100%;
  resize: none;
  border: none;
  background: transparent;
  font-size: 16px;
  height: 400px;
  outline: none;
  padding: 0.4rem 0.6rem;
  border-radius: 8px;
  border: 2px solid transparent;
  transition: 0.3s;
  &:hover {
    border: 2px solid #8f8f8f;
  }
  &:focus {
    border: 2px solid #494949;
  }
}

.metaDescription {
  @extend .multilineInput;
  height: 200px;
  padding: 0;
  margin-top: 20px;
  border: none !important;
  &:hover {
    border: none;
  }
  &:focus {
    border: none;
  }
}

.countries {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 2rem;
}

.countries {
  font-size: 1rem;
  color: rgb(0, 124, 226);
  font-weight: 500;
  cursor: pointer;
  &:hover {
    color: rgba(0, 124, 226, 0.827);
  }
}

.brands {
  margin-top: 1.5rem;
  .brands_header {
    font-size: 1.5rem;
  }
}

.loading_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000000;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.57);
  p {
    color: white;
    font-size: 1rem;
  }
}
.error {
  color: red;
  font-size: 0.6rem;
  font-weight: 400;
  text-emphasis: true;
  margin-top: 0.2rem;
}

.btns {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.counter {
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 12px;
  color: rgb(13, 110, 253);
}

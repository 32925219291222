.featuresList {
  margin-top: 2rem;
  margin-left: 20px;
  margin-bottom: 4px;
  padding: 0;
  li {
    margin-bottom: 1rem;
    font-weight: 400;
    width: 100%;

    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }
}

.addContainer {
  cursor: pointer;
}

@use "../../global/constants.scss";

.main {
  margin-top: 4rem;
  align-items: center;

  em {
    margin-top: 1rem;
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 1rem;
    width: 100%;
    max-width: 300px;
  }
}

.logo {
  width: 150px;
  object-fit: contain;
}
.cta {
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: 400;
}

.names {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.btn {
  border-radius: 12px;
  height: 45px;
}

.clickable {
  cursor: pointer;
  color: #184e92;
  font-weight: 600;
  transition: 0.3s ease;
  &:hover {
    color: #184f92c9;
  }
}

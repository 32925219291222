.header_text {
  width: max-content;
  margin: 2rem 25px;
  list-style: none;
  position: relative;
  user-select: none;
}
.news_list {
  //should be a grid of two columns
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  margin: 0 25px;

  padding: 0 5rem;
  //for mobile view
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 560px) {
    padding: 0 1rem;
  }
  @media (max-width: 450px) {
    padding: 0 0.5rem;
  }
}

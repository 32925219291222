#mainContainer {
  margin: 0;
  display: flex;
  flex-direction: column;
}
section {
  padding: 0;
}
.header {
  font-size: 1rem;
  font-weight: 500;
}
.title {
  padding: 2rem 3rem;
}
.content {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.stats_container {
  display: flex;

  flex-direction: row;
  flex: 5;
}
.stats {
  padding: 1rem;
  display: flex;
  flex-direction: column;

  flex: 1;
  gap: 10px;

  .level {
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
}
.stocks {
  padding: 1rem;
  display: flex;
  flex: 1;
  .stocks_container {
    background-color: white;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    padding: 0.5rem;
  }
}
.divider {
  border: 1px dashed;
  margin: 10px 0;
}
.mail {
  display: flex;
  overflow: hidden;
  flex: 5;
  max-height: 300px;
  padding: 0 1rem 1rem 1rem;
  .mail_container {
    padding: 0.5rem;
    border-radius: 8px;
    overflow: scroll;

    height: 100%;
    width: 100%;
    background-color: white;
  }
}

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  p {
    max-width: 400px;
    text-align: center;
  }
}

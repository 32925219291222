.contact_input {
  p {
    font-size: 0.8rem;
  }
  padding-right: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.6rem;
  display: flex;
  padding-right: 2rem;
  margin-left: 1rem;

  input {
    font-size: 0.8rem;
  }
}
.input_m {
  font-size: 0.8rem;
  width: 100%;
  outline: none;
  border: none;
}

.hint_text {
  margin-top: 0.4rem;
  font-size: 0.9rem;
  font-weight: 500;
}

.add_button {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 1rem;
  em {
    user-select: none;
    cursor: pointer;
    color: #2b79c2;
    font-weight: 500;
  }
}

.contact_input_list {
  max-height: 200px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  gap: 0.3rem;
}

@use "../../../global/constants.scss";

.products .text_light {
  text-align: center;
  width: 70%;
  margin: 0.9rem auto;
}
.product {
  cursor: pointer;
  display: flex;
  margin: 2rem;
  position: relative;
  flex-direction: column;
  transition: 0.3s;
  &:hover img {
    transform: scale(1.1);
  }
  @media (max-width: 1424px) {
    margin: 2rem 0.5rem;
  }
}
.product_content {
  padding: 1rem;

  border-radius: 8px;
  cursor: pointer;
  position: relative;
}
.product_discount {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  .discount {
    border-radius: 0 0 8px 0;
    display: inline-block;
    background-color: constants.$discount-color;
    padding: 0.5rem 0.2rem;
    position: relative;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    color: white;
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 3px;
  }
}
.product_img {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  border-radius: 50%;
  transition: 0.5s ease;
  display: flex;
  justify-content: center;
  // transform: scale(1.1);
  > div {
    width: 100%;
  }
  img {
    width: 175px;
    height: 175px;
    object-fit: contain;
    z-index: -1;
    transition: transform 0.6s ease;
  }
}

.product_img_out {
  @extend .product_img;
  opacity: 0.6;
}

.product_info {
  margin-top: 0.6rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}
.sold_extend {
  @extend .product_info;
  justify-content: space-between;
}

.sold_out {
  font-size: 12px;
  font-weight: 500;
  color: red;
}

.product_name {
  color: black;
  display: block;
  text-decoration: none;
  font-size: 1rem;
  // text-transform: uppercase;
  font-weight: 500;
}
.product_cat {
  color: #717171;
  font-size: 0.625rem;
  text-transform: uppercase;
  text-decoration: underline;
  font-weight: 500;
  letter-spacing: -0.5px;
  width: max-content;
  transition: 0.3s;
  &:hover {
    color: #0560b5;
  }
}
.product_price {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1.5px;
  display: inline-block;
}
.discounted_o {
  @extend .product_price;
  text-decoration: line-through;
  color: rgb(116, 116, 116);
}
.discounted {
  @extend .discounted_o;
  text-decoration: none;
  color: red;
}

.off_info .sm_title {
  background: var(--carribean-green);
  color: white;
  display: inline-block;
  padding: 0.5rem;
  position: absolute;
  top: 0;
  left: 0;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  z-index: 1;
  letter-spacing: 3px;
  cursor: pointer;
}

@media (max-width: 1340px) {
  .product {
    margin: 0.5rem;
  }
  .product_content {
    padding: 0.3rem;
    padding-bottom: 0.5rem;
  }
}

@media (max-width: 700px) {
  .product_img {
    width: auto;
    height: auto;
    img {
      width: 150px;
      height: 150px;
    }
  }
  .product_name {
    margin-top: 1rem;
    font-size: 0.8rem;
  }
  .product_cat {
    font-size: 0.5rem;
    font-weight: 400;
  }
}

@media (max-width: 380px) {
  .product_img {
    img {
      width: 125px;
    }
  }
}

.product_sold_out {
  position: absolute;
  z-index: 5;
  right: 0;
}

.card_body {
  z-index: 99999;
}

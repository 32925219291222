.sales_list {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;

  .sales_item {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-size: 1rem;
    .image {
      height: 50px;
      width: 50px;
      background-color: red;
    }
  }
}

.divider {
  border: 1px dashed;
  margin: 10px 0;
}

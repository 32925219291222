.formContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 3;
}
.billingForm {
  display: flex;
  flex-direction: column;
  flex: 3;
  padding: 0 4rem;
  gap: 1rem;
  @media (max-width: 500px) {
    padding: 0 0;
  }
}

.double {
  display: flex;
  gap: 2rem;
  div {
    flex: 1;
  }
}
.select {
  margin-top: 1rem;
  outline: none;
  border-radius: 6px;
  border: 1px solid #373737;
  padding: 0.3rem 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  resize: none;
  height: 40px;
  font-size: 0.9rem;
  font-weight: 400;
  color: #777777;
}

.textarea {
  width: 100%;
  resize: none;
  height: 120px;
  border: 1px solid #373737;
  border-radius: 6px;
  padding: 0.3rem 0.5rem;
  font-size: 0.9rem;
  outline: none;
}
.errorMessage {
  color: red;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  font-style: italic;
  text-align: center;
}

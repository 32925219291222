#main {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.add_button {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.contact_card_list {
  flex-direction: column;
  display: flex;
  gap: 1.2rem;
}

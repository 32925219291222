.main {
  width: 100%;
  height: 100%;
}
.editor {
  height: 100%;
}
.editor > div {
  height: 100%;
}

.brand_card {
  user-select: none;
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  height: 50px;
  background-color: rgb(232, 232, 232);
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid transparent;

  &:hover {
    border: 2px solid #a9a9a9;
  }
}

.selected {
  @extend .brand_card;
  border: 2px solid #2c2c2c;
}

.deleteButton {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
  &:hover {
    path {
      fill: red;
    }
  }
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  padding: 0 2rem;
  p {
    font-size: 1rem;
    font-weight: 600;
  }
  .cat {
    flex: 1;
  }
}

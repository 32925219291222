@use '../../../../../global/constants.scss';

#main {
  td {
    border: 2px solid rgb(179, 179, 179);
    border: collapse;
  }

  table {
    table-layout: fixed;
    width: 100%;
  }
  td {
    height: 50px;
    background-color: white;
    padding: 0.3rem;
    color: rgb(110, 109, 109);
    font-weight: 400;
    font-size: 0.9rem;
  }
  th {
    text-align: center;
    font-size: 0.9rem;
    font-weight: 400;
    padding-bottom: 1rem;
  }
}

.description {
  width: 350px;
}

.title {
  width: 150px;
}

.add {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.button_main {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.5rem;
}

.button {
  background-color: constants.$admin-main-color;
  padding: 0.5rem 2rem;
}

.removeDiv {
  width: 2px;
}

.cards_list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

@use '../../../global/constants.scss';
.tab {
  border-radius: 8px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 34px;
  cursor: pointer;
  transition: 0.3s ease-out;
  &:hover {
    background-color: aliceblue;
  }
  svg {
    vertical-align: baseline;
  }
}
.openTab {
  @extend .tab;
  padding: 1rem 0.5rem;
}
.closedTab {
  @extend .tab;
  gap: 0;
  padding: 0.5rem 0.5rem;
}
.icon {
  height: 20px;
  width: 20px;
}
.tabName {
  margin-top: 0;
  transition: 0.3s ease-out;
}

.selected_tab {
  @extend .tab;
  background-color: constants.$admin-main-color;
  color: white;
  &:hover {
    background-color: #000b6a;
  }
}
.tabNameHidden {
  width: 0;
  opacity: 0;
}

#form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 1rem;
  width: 100%;
  max-width: 340px;
  border-radius: 8px;
  border: 1px solid rgba(128, 128, 128, 0.318);
  background-color: #f6f8fa;
  padding: 1rem;
}

.btn {
  border-radius: 6px;
  height: 40px;
  background-color: #184e92;
}

.error {
  color: red;
  font-size: 12px;
  margin-bottom: 5px;
  text-align: center;
}

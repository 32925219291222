@use "../../../global/constants.scss";

.cart_content {
  flex: 3;
}

.cart_header {
  display: flex;
  flex: 1;
  gap: 1rem;
  padding-bottom: 1rem;

  border-bottom: 1px solid rgb(172, 172, 172);
  h6 {
    margin: 0;
  }
}

.item_list {
  margin-top: 1rem;
}
.content_details {
  display: flex;

  flex: 1;
  font-size: 1rem;
  color: rgb(55, 55, 55);
  justify-content: center;
}

.content_header {
  @extend .content_details;
  justify-content: start;
  flex: 4;
}

.btns {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 2px solid;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  width: fit-content;
  transition: 0.3s;
}

.outline {
  @extend .btn;
  color: constants.$primary-color;
  border-color: constants.$primary-color;
  &:hover {
    background-color: constants.$primary-color;
    color: white;
    path {
      fill: white;
    }
  }
}

.inline {
  @extend .btn;
  color: white;
  background-color: constants.$primary-color;
  border-color: constants.$primary-color;
  &:hover {
    opacity: 0.8;
  }
}

.back_arrow {
  height: 15px;
  width: 15px;
  transform: rotate(90deg);

  path {
    transition: 0.3s;

    fill: constants.$primary-color;
  }
}

@media (max-width: 450px) {
  .btns {
    flex-direction: column;
  }
}

.main {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.order_manager {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.oM_h {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
}

.entitle {
  flex: 1;
  h3 {
    font-size: 1.5rem;
    font-weight: 400;
  }
}

.filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  input {
    outline: none;
    border: 1px solid grey;
    background-color: white;
    padding: 0.2rem 0.5rem;
    border-radius: 6px;
    font-size: 0.9rem;
  }
  select {
    outline: none;
    border: none;
    background-color: transparent;
    font-size: 0.9rem;
    font-weight: 500;
  }
  p {
    color: grey;
  }
}
.oM_b {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  gap: 1rem;
  margin-top: 1rem;
  .oM_b_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    background-color: #f0f0f0;
    flex: 1;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background-color: #e0e0e0;
    }
  }
}

.oM_b_h {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 1rem;

  p {
    flex: 1;
    font-size: 0.9rem;
    font-weight: 500;
    color: rgb(152, 152, 152);
  }
}

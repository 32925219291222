#mainPage {
  background-color: #ededed;
  display: flex;
  flex-direction: row;
}

.gridContainer {
  width: 100%;
  height: 100%;
  gap: 20px;
  display: grid;
  grid-template-areas:
    'a b c c'
    'd e c c'
    'f f f f'
    'f f f f';
  div {
    background-color: red;
    border-radius: 8px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }
}
.a {
  grid-area: a;
}
.b {
  grid-area: b;
}
.c {
  grid-area: c;
}
.d {
  grid-area: d;
}
.e {
  grid-area: e;
}
.f {
  grid-area: f;
}

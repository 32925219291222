.main {
  width: 100%;
}
.main_image {
  position: relative;

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
}
.image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: 50% 50%;
}
.main_low_container {
  align-items: start;
  width: 100%;
  overflow: auto;
  white-space: nowrap;
  padding: 0 1rem 1rem 1rem;
  margin-bottom: 4rem;
}

.low_image_container {
  display: inline-block;
  position: relative;
  width: 75px;
  height: 75px;
  overflow: hidden;
  border-radius: 0.7rem;
  //   border: 1px solid #484848;
  cursor: pointer;
  justify-content: center;
  align-items: center;

  img {
    position: absolute;
    top: 0;
    height: 100%;
    width: inherit;
    object-fit: contain;
  }
}
.blur {
  top: 0;
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  transition: 0.3s;
  &:hover {
    background-color: rgba(0, 0, 0, 0.198);
  }
  border-radius: 1rem;
}

@use "../../../../../../global/constants.scss";

.main_card {
  display: flex;
  flex-direction: row;
  padding: 1rem 2rem;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
  #form {
    display: flex;
    flex: 1;
  }
}
.visual {
  font-family: "open sans", sans-serif;
  flex: 1;
  width: 600px;
}
.siteName {
  color: #202124;
  font-size: 13px;
  max-width: 200px;
  display: block;
  line-height: 20px;
  white-space: nowrap;
  letter-spacing: 0;
  font-weight: 500;
}

.heading {
  display: block;
  overflow: hidden;
  -webkit-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 20px;
  letter-spacing: 0;
}

.image_container {
  margin-right: 15px;
}

.image {
  width: 28px;
  height: 28px;
  background-color: rgb(200, 200, 200);
  border-radius: 60px;
}

.header_main {
  display: flex;
  align-items: center;
}

.url {
  font-size: 10px;
}

.header {
  color: #1b3eb2;
  font-size: 17px;
  margin-top: 15px;
  font-weight: 600;
  margin-bottom: 0;
}

.description {
  font-size: 13px;
  letter-spacing: 0;
  color: #4d5156;
}

.edit {
  flex: 1;
  align-items: flex-start;
  display: flex;
  height: 100%;
}

.page_image {
  width: 70px;
  height: 70px;
  border-radius: 60px;
  background-color: #303e6a;
  object-fit: cover;
}

.entries {
  width: 100%;
  max-width: 350px;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}

.page_title {
  width: 100%;
}
.input_title {
  font-size: 1rem;
  font-weight: 500;
}

.page_description {
  margin-top: 1rem;
  width: 100%;
}

.input {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  min-height: 60px;
  max-height: 200px;
  border-radius: 6px;
  padding: 0.5rem 0.5rem;
  background: white;
  border: 2px solid #373737;
  width: 100%;
  resize: vertical;
  font-size: 0.8rem;
  font-weight: 400;
  &:focus {
    outline: none;
  }
}

.save_container {
  flex: 1;
  display: flex;
  height: 100%;
  align-items: flex-end;
  justify-content: center;
}

.save_button {
  height: min-content;
  padding: 0.5rem 1rem;
  background-color: constants.$admin-main-color;
  color: white;
  border-radius: 6px;
  outline: none;
  border: none;
}

.counter {
  display: flex;
  justify-content: flex-end;
  font-size: 0.6rem;
  color: #4d5156;
  margin-top: 0.2rem;
}

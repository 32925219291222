:root {
  --gutter: 20px;
}

.car_main {
  justify-content: space-between;
  border-radius: 12px;
  width: 100%;
  background: white;
  overflow-x: scroll;
  padding: var(--gutter) 0;
  display: grid;

  grid-template-columns: var(--gutter) 1fr var(--gutter);
  align-content: start;
}
.car_main > * {
  grid-column: 2 / -2;
}

.car_main > .hs {
  grid-column: 1 / -1;
}

.hs {
  height: 200px;
  align-items: center;
  position: relative;
  display: grid;

  grid-template-columns: 10px;
  grid-template-rows: minmax(150px, 1fr);
  grid-auto-flow: column;
  grid-auto-columns: calc(45% - var(--gutter) * 2);

  overflow-x: scroll;
  scroll-snap-type: x proximity;
  // padding-bottom: calc(0.75 * var(--gutter));
  // margin-bottom: calc(-0.25 * var(--gutter));
  &:before,
  &:after {
    content: '';
    width: 10px;
  }

  scrollbar-width: none;
  margin-bottom: 0;
  padding-bottom: 0;

  &::-webkit-scrollbar {
    display: none;
  }
  li,
  .item {
    scroll-snap-align: center;
    padding: calc(10px / 2 * 1.5);
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    max-height: 200px;
    overflow: hidden;

    img {
      overflow: hidden;
      object-fit: cover;
      height: 200px;
      width: 100%;
      border-radius: 6px;
    }
  }
}

.no_image {
  height: 300px;
}

.addImage_container {
  position: relative;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.3rem;
  p {
    cursor: pointer;
    font-weight: 500;
    color: #007bff;
  }
}

.remove {
  position: absolute;
  right: 10px;
  top: 10px;
}

.file_input {
  display: none;
  opacity: 0;
  z-index: -9999;
}

@use "../../../../../../global/constants.scss";
.main_card {
  width: 100%;

  background-color: #fff;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.image_container {
  cursor: pointer;
  position: relative;
  height: 360px;
  flex: 1.2;
  background-color: rgb(109, 109, 109);
  justify-content: center;
  align-items: center;
  display: flex;
}
.cover {
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  transition: 0.3s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
.image {
  cursor: pointer;
  width: 100%;
  height: inherit;
  object-fit: cover;
}
.file_upload {
  display: none;
  opacity: 0;
  z-index: -999;
}
.address {
  margin-bottom: 0.8rem;

  flex: 1;
  width: auto;
  .input {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    padding-right: 2rem;
    margin-left: 1rem;
  }
}
.hint_text {
  margin-top: 0.4rem;
  font-size: 0.9rem;
  font-weight: 500;
}
.contact {
  flex: 1;
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.main_div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 0.3rem;
  margin-bottom: 0.3rem;
  gap: 0.5rem;
}
.save_button {
  outline: none;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 0.7rem;
  font-weight: 500;
  width: min-content;
  padding: 0.3rem 0.7rem;
  border-radius: 5px;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  background-color: constants.$admin-main-color;
}

.delete {
  @extend .save_button;
  background-color: rgb(205, 11, 11);
}

.time {
  padding-right: 2rem;
  margin-top: 0.4rem;
  margin-left: 1.3rem;
}
.time_header {
  display: flex;
}
.time_days {
  flex: 1;
}
.time_entry {
  display: flex;
  flex-direction: row;
}
.time_input {
  flex: 1;
  input {
    font-size: 0.7rem;
  }
}

.cities {
  max-width: 200px;
  width: 200px;
  outline: none;
}
.generative_btn {
  color: rgb(3, 105, 229);
  font-size: 0.7rem;
  font-weight: 500;
  cursor: pointer;
  font-style: italic;
  &:hover {
    color: rgb(9, 77, 160);
  }
}

.countries {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 1rem;
}

.countries {
  font-size: 1rem;
  color: rgb(0, 124, 226);
  font-weight: 500;
  cursor: pointer;
  &:hover {
    color: rgba(0, 124, 226, 0.827);
  }
}

.countrySelection {
  display: flex;
  font-size: 0.6rem;
  color: #989898;
}

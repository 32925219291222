#sideBar {
  position: absolute;
  z-index: 999;
  background-color: white;
  margin: 0;
  height: 100%;
  overflow: hidden;
  transition: 0.5s ease-in;
  .subHeader {
    margin-top: 45px;
    color: grey;
    font-weight: 600;
  }
}
.subHeader,
.header {
  transition: 0.4s ease-in;
}
.closed {
  .subHeader,
  .header {
    width: 0;
    opacity: 0;
  }
  display: flex;
  // justify-content: center;
  padding: 0;
  width: 50px;
}
.open {
  .subHeader,
  .header {
    width: auto;
    opacity: 1;
  }
  display: inline;
  padding: 30px;
  width: 270px;
}
.header {
  text-align: center;
  margin: 0;
}

.tabList {
  margin: 35px auto;
  margin-bottom: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: 15px;
  transition: 0.2s;
}

.main {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.cM_b {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  gap: 1rem;
  margin-top: 1rem;
  .cM_b_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    background-color: #f0f0f0;
    flex: 1;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background-color: #e0e0e0;
    }
  }
}

.cM_b_h {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 1rem;

  p {
    flex: 1;
    font-size: 0.9rem;
    font-weight: 500;
    color: rgb(152, 152, 152);
  }
}

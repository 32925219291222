@use "../../global/constants.scss";

.alert_background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 99999999999;
}

.alert_overlay {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  top: -0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.335);
  z-index: 90;
}
.alert_container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 6px;
  height: 220px;
  width: 450px;
  background-color: white;
}
.sep {
  display: flex;
  flex: 1;
  width: 100%;
}

.buttons_container {
  display: flex;
  flex-direction: row-reverse;
  gap: 1rem;
}
.container {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d0d0d0;
  width: 75px;
  height: 35px;
  border-radius: 6px;
  border: 1px solid;
  transition: 0.3s;

  &:hover {
    border: 2px solid;
  }
}

.container_s {
  @extend .container;
  background-color: constants.$admin-main-color;
  color: white;
  border-color: constants.$admin-main-color;
  // font-weight: 600;
  &:hover {
    border-color: #000850c5;
    background-color: #000850c5;
  }
}

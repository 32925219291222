input {
  color: rgb(117, 117, 117);
  font-size: 0.9rem;
  font-weight: 500;
  background-color: transparent;
  outline: none;
  border: none;
}

.input {
  @extend input;
  color: black;
}

.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.container {
  display: flex;
  z-index: 10000;
  flex-direction: column;
  width: 60%;
  height: 60%;
  background-color: white;
  border-radius: 8px;
  padding: 1rem;
  .header {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0;
  }
  span {
    letter-spacing: 0;
    font-size: 0.9rem;
    font-weight: 400;
    margin: 0;
    color: #494949;
  }
}

.search {
  display: flex;
  span {
    width: 100%;
  }
}

.countriesList {
  flex: 1;
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 1rem;
  row-gap: 0.5rem;
  overflow: scroll;
  margin-top: 1.2rem;
}

.button {
  cursor: pointer;
  padding: 0.2rem 0.5rem;

  p {
    text-align: end;
    color: #0086d3;
  }
}

.countriesManySelector {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.selectorMass {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #ededed;
  padding: 0.1rem 0.4rem;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background-color: #dcdcdc;
  }
}

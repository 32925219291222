.mains {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  > img {
    width: 200px;
    height: 200px;
    object-fit: contain;
  }
}

.mainContainer {
  user-select: none;
  cursor: pointer;
  padding: 0.3rem 0.7rem;
  background-color: #2b2b2b84;
  border: 2px solid #2b2b2b;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    margin: 0;
    color: white;
    font-size: 10px;
    height: max-content;
  }
}

.selected {
  @extend .mainContainer;
  background-color: #2b2b2b;
}

.news_item {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.image_container {
  background-color: grey;
  width: 115px;
  height: 115px;
  border-radius: 8px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
}
.news_content {
  flex: 5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.news_header {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
}
.news_description {
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.2;
  margin: 0;
}
.news_lower {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.news_category {
  font-size: 0.7rem;
  font-weight: 700;
  font-style: italic;
  color: grey;
}
.news_external_link {
  font-size: 0.7rem;
  font-weight: 700;
  color: #174e92;
  text-decoration: underline;
  cursor: pointer;
}

.container {
  cursor: pointer;
  padding: 0.2rem 0.5rem;
  width: min-content;
  border-radius: 6px;
}
.pending {
  @extend .container;
  background-color: #fff8e1;
  color: #ffc107;
}
.confirmed {
  @extend .container;
  background-color: #e8f5e9;
  color: #28a745;
}
.cancelled {
  @extend .container;
  background-color: #f8e6e8;
  color: #dc3545;
}
.shipped {
  @extend .container;
  background-color: #e3f2fd;
  color: #007bff;
}
.received {
  @extend .container;
  background-color: #e8f5e9;
  color: #4caf50;
}
.returned {
  @extend .container;
  background-color: #ffe0b2;
  color: #ff5722;
}
.refunded {
  @extend .container;
  background-color: #f8f9fa;
  color: #6c757d;
}

.section {
  margin: 0;
  background-color: rgb(240, 240, 240);
  padding: 2rem 2rem;
  display: flex;
  justify-content: center;
}
.container {
  width: 100%;
  max-width: 1920px;
  display: flex;
}

.service {
  flex: 1;
  display: flex;
  gap: 2rem;
  justify-content: center;
  svg {
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    svg {
      transform: scale(1.5);
    }
  }

  &:nth-child(1) {
    border-right: solid grey 1px;
  }
  &:nth-child(2) {
    border-right: solid grey 1px;
  }
}

.text {
  h3 {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
}

@media (max-width: 1150px) {
  .section {
    padding: 2rem 1rem;
  }
}

@media (max-width: 1000px) {
  .text {
    h3 {
      font-size: 1rem;
    }
    p {
      font-size: 0.8rem;
    }
  }
}
@media (max-width: 700px) {
  .section {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
  .container {
    flex-direction: column;
  }
  .service {
    flex-direction: column;
    align-items: center;
    gap: 0;
    text-align: center;
    margin-bottom: 1rem;

    &:nth-child(1) {
      border-right: none;
    }
    &:nth-child(2) {
      border-right: none;
    }
  }
  .text {
    h3 {
      margin-bottom: 0;
    }
  }
}

.recommendation {
  > h3 {
    padding: 0 2rem;
  }
  align-items: start;
  width: 100%;

  white-space: nowrap;
  margin-bottom: 4rem;
  .list {
    padding: 0 2rem;
    overflow: auto;
  }
}
.object {
  display: inline-block;
  width: 300px;
  margin-right: 20px;
}

@media (max-width: 850px) {
  .recommendation {
    > h3 {
      padding: 0 1rem;
    }
  }
  .list {
    padding: 0 1rem;
  }
  .object {
    width: auto;
  }
}

.oM_b_c {
  border-top: 3px dashed #dfdccc;
  display: flex;
  padding-top: 1rem;
  .card {
    color: rgb(117, 117, 117);
    flex: 1;
    font-size: 0.9rem;
    font-weight: 500;
  }
  p {
    @extend .card;
  }
  p:first-of-type {
    color: black;
  }
}

.container {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  resize: none;
  height: 40px;
  border-radius: 6px;
  padding: 0.3rem 0.5rem;
  background: white;
  border: 1px solid #373737;
  span {
    letter-spacing: 0;
    color: #7b7b7b;
    font-size: 0.8rem;
    font-weight: 400;
  }
}

.error {
  @extend .container;
  border-color: red;
}

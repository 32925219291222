.cat_editor {
  display: flex;
  flex-direction: column;
}

.cat_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    margin: 0;
    font-size: 1rem;
  }
}

.sort_container {
  cursor: pointer;
  padding: 0.5rem 1rem;
  text-align: center;
  display: flex;
  background-color: #ededed;
  font-weight: 500;
  font-size: 1rem;
  border: none;
  font-family: 'rubik', sans-serif;
  -webkit-appearance: none;
  outline: none;
  transition: 0.3s;
  &:hover {
    color: rgb(65, 65, 65);
    background-color: whitesmoke;
  }
}
.cat {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.cat_list {
  display: flex;
  gap: 1rem;
}
.cat_item {
  display: flex;
  padding: 0.2rem 0.5rem;
  border: 2px solid black;
  border-radius: 60px;
  background-color: #999;

  span {
    color: white;
    font-size: 0.7rem;
    font-weight: 400;
  }
}

.add_container {
  display: flex;
  justify-content: flex-end;
}
.add_text {
  cursor: pointer;
  width: fit-content;
  color: #137ce6;
}
.selector {
  gap: 1rem;
  display: flex;
  align-items: end;
  .text_button {
    font-size: 1rem;
    font-weight: 500;
    color: #848484;
    cursor: pointer;

    &:hover {
      color: rgb(34, 34, 34);
    }
  }
  .selected {
    @extend .text_button;
    font-size: 1.2rem;

    color: black;
  }
}

.basePrice {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  p {
    font-weight: 500;
    color: #484848;
  }
  input {
    font-size: 1rem;
    font-weight: 500;
    color: #484848;
  }
}
details {
  summary {
    margin: 1rem 0;
    font-size: 1rem;
    font-weight: 500;
    color: #484848;
  }
  .price {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
    p {
      font-weight: 500;
      color: #484848;
    }
    input {
      font-size: 1rem;
      font-weight: 500;
      color: #484848;
    }
  }
}
.priceRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  .priceTitle {
    flex: 1;
    font-size: 0.8rem;
    font-weight: 500;
  }
}

//colors
$primary-color: #174e92;
$primary-black: #232526;
$secondary-color: #444444;

$discount-color: #e52020;

$admin-main-color: #000850;

//Paddings
$horizontal-Paddging: 5%;

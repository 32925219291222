.layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 300000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.offerContainer {
  height: 500px;
  width: 500px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  position: relative;
}
.closeContainer {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.271);
  width: fit-content;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 10px;
}
.offerImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 16px;
}
.arrow {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.271);
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  rotate: 90deg;
  cursor: pointer;
  border-radius: 50%;
}
.right {
  right: 10px;
  rotate: -90deg;
}

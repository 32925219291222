.header {
  font-size: 1rem;
  font-weight: 500;
}
.card {
  padding: 0.5rem;
  justify-content: space-around;
  flex-direction: column;
  height: 100%;
  display: flex;
  flex: 1;
  background-color: white;
  border-radius: 8px;
}

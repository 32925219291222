@use "../../global//constants.scss";
section {
  margin: 65px auto;
  padding: 0 constants.$horizontal-Paddging;
  transition: 0.2s ease;
  width: 100%;
  margin-top: 0;
}

#services {
  margin-top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.servicesHeadline {
  max-width: 450px;
  text-align: center;
}

.services_cards {
  margin-top: 3.875rem;
  width: 100%;
  justify-content: center;
  gap: 5%;
  display: flex;
  flex-direction: row;
}

.head_prod {
  display: flex;
  align-items: center;

  width: 100%;

  hr {
    flex: 1;

    display: block;
  }
  span {
    font-size: 1.5rem;
    font-weight: 600;
  }
}

@media (max-width: 850px) {
  section {
    margin: 2rem auto;
    margin-top: 0;
  }
  .brand_container {
    img {
      width: 101px;
    }
  }
}

@media (max-width: 680px) {
  .services_cards {
    flex-direction: column;
    align-items: center;
    gap: 3rem;
  }
}

@media (max-width: 480px) {
  .servicesHeadline {
    max-width: 350px;
  }
  .brand_container {
    img {
      width: 70px;
    }
  }
}

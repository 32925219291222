.main {
  cursor: auto;
  height: auto;
  width: 300px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.069);
  z-index: 999999;
  top: 100%;
  right: 60%;
  position: absolute;
  border-radius: 6px;
  padding: 0.7rem 1rem;
}

.emptyMessage {
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  color: grey;
}

.list_products {
  padding: 0 0 0.4rem 0;
  border-bottom: 1px solid #eee;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.product_card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.image_container {
  width: 50px;
  height: 50px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 6px;
}

.product_name {
  font-size: 1rem;
  font-weight: 600;
  color: #184e92;
  width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product_price_container {
  display: flex;
  gap: 5px;
  justify-content: start;
  align-items: center;
}
.product_price {
  font-size: 0.8rem;
  font-weight: 400;
  color: rgb(121, 121, 121);
}
.product_price_icon {
  @extend .product_price;
  font-size: 0.6rem;
}
.cart_footer {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.cart_button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
  color: white;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.view_cart,
.checkout {
  @extend .cart_button;
}

.view_cart {
  background-color: #184e92;
  &:hover {
    background-color: #2067be;
  }
}

.checkout {
  background-color: #e5211f;
  &:hover {
    background-color: #f03a39;
  }
}

.divider {
  margin: 0.45rem 0;
  border: dashed 1px rgba(0, 0, 0, 0.23);
}
.subTotal {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
}
.text {
  font-size: 0.8rem;
  font-weight: 400;
}
.totalPrice {
  font-size: 1rem;
  font-weight: 600;
}

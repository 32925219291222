.news_editor {
  z-index: 1000;
  width: 80%;
  // height: 80%;
  background-color: white;
  border-radius: 16px;
  padding: 20px;
  position: relative;
  display: flex;
  gap: 15px;
}

.vr_image {
  width: 200px;
  height: 400px;
  border-radius: 8px;
  background-color: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.content {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.hr_image {
  width: 100%;
  height: 250px;
  background-color: grey;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.title_input {
  width: 100%;
  font-size: 2rem;
  border: none;
  margin: 10px 0;
}

.description_input {
  width: 100%;
  font-size: 1rem;
  border: none;
  margin: 10px 0;
  resize: none;
  height: 280px;
  padding: 5px;
  // in and out transition
  transition: border 0.3s;
  border: 1px solid transparent;

  &:focus {
    //change outline color
    outline: none;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
}

.category_input {
  width: 100%;
  font-size: 0.8rem;
  border: none;
  margin: 10px 0;
  padding: 5px;
  font-style: italic;
}

.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  button {
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    background-color: #f1f1f1;
    cursor: pointer;
    transition: background-color 0.3s;
    &:hover {
      background-color: #e1e1e1;
    }
  }
}

.input_file {
  display: none;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

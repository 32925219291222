@use "../../global/constants.scss";
.main {
  width: 100%;
}
.steps {
  margin-top: 1.5rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
  h4 {
    font-weight: 300;
    font-size: 1.3rem;
    color: rgb(189, 189, 189);
    @media (max-width: 450px) {
      font-size: 1rem;
    }
  }
  .selected {
    color: rgb(0, 0, 0);
    font-weight: 500;
  }
}

.render {
  display: flex;
  flex: 1;
  justify-content: center;
}

.mainContainer {
  margin-top: 2rem;
  display: flex;
  flex: 1;
  max-width: 80%;
  gap: 2rem;
}
.prices {
  flex: 2;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-left: 2px solid constants.$primary-color;

  padding: 0.5rem 1rem;
}

.bottom-border {
  border-bottom: 2px solid grey;
}
.prices_header {
  @extend .bottom-border;
}

.prices_details {
  @extend .bottom-border;
  border-color: rgb(218, 218, 218);
  border-width: 1px;
  display: flex;
  justify-content: space-between;
  p:first-of-type {
    font-weight: 500;
    color: rgb(70, 70, 70);
  }
}

.prices_btn {
  cursor: pointer;
  width: 100%;
  background-color: #d44b64;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.3rem 0;
  display: flex;
  justify-content: center;
  border-radius: 6px;
  transition: 0.3s;
  &:hover {
    opacity: 0.8;
  }
}

.selector {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  input {
    cursor: pointer;
  }
}

@media (max-width: 650px) {
  .mainContainer {
    flex-direction: column;
  }
}

.input {
  width: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;

  &:focus {
    cursor: text;
  }
}

.title {
  @extend .input;
  text-align: center;
  font-size: 2.1785rem;
  font-weight: 500;
}

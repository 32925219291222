.title {
  padding: 2rem 3rem;
}
.notificationComposer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  > input {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
}

.valid {
  color: green;
  font-style: italic;
}

.error {
  color: red;
  font-style: italic;
}

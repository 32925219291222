:root {
  --gutter: 20px;
}

.main {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  form {
    display: flex;
    flex-direction: row;
  }
}

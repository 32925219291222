.warranty {
  user-select: none;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  //   justify-content: center;
  align-items: center;
  p {
    font-size: 20px;
    font-weight: 500;
  }
}

.arrows {
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  svg {
    cursor: pointer;
  }
}

.warrantyText {
  display: flex;
  flex-direction: row;
  gap: 6px;
  width: 100%;
}

.period {
  padding: 0 0.4rem;
  cursor: pointer;
  background-color: rgb(212, 212, 212);
  border-radius: 8px;
  transition: 0.4s;
  &:hover {
    background-color: rgb(177, 177, 177);
  }
}

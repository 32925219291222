.countryCard {
  height: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.countryName {
  font-size: 0.9rem;
  font-weight: 400;
}

.notFound {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #999;
}

.cardList {
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
}

.mainContainer {
  // border-radius: 8px;
  overflow: hidden;
  max-width: 1900px;
  width: 100%;
  // height: initial;
  margin: auto;

  img {
    height: 450px;
    object-fit: contain;
    // border-radius: 8px;
    overflow: hidden;
  }
}

@media (max-width: 480px) {
  .mainContainer {
    // height: 200px;

    img {
      overflow: hidden;
      // height: 200px;
    }

    p {
      display: none;
    }
    h3 {
      display: none;
    }
  }
}

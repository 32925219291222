.oM_b_c {
  position: relative;
  border-top: 3px dashed #dfdccc;
  display: flex;
  padding-top: 1rem;
  .card {
    color: rgb(117, 117, 117);
    flex: 1;
    font-size: 0.9rem;
    font-weight: 500;
  }
  p {
    @extend .card;
  }
  .input {
    @extend.card;
    background-color: transparent;
    outline: none;
    border: none;
  }
  .iB {
    @extend .input;
    color: black;
  }
}
.actionButton {
  position: absolute;
  right: 0;
  font-size: small;
  color: rgb(15, 131, 233);
  cursor: pointer;
  &:hover {
    color: rgba(15, 131, 233, 0.698);
  }
}

.main {
  display: flex;
  flex-direction: column;

  flex: 1;
  width: 100%;
}
.ct_1 {
  display: flex;
  flex: 1;
  gap: 2rem;
}
.ct_2 {
  display: flex;
  flex-direction: column;

  flex: 1;
}
.list {
  flex-direction: column;
}
.description {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.6rem;
  flex: 1;
  height: 100%;
  width: 100%;
}
.version {
  flex: 1;
  margin-bottom: 30px;
}
.input {
  padding: 0.3rem 1rem 0.4rem 1rem;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 100% !important;
  resize: none;
  border-radius: 6px;
  transition: 0.5s;
  &:focus {
    background-color: rgb(221, 221, 221);
  }
  &:hover {
    background-color: rgb(227, 227, 227);
  }
}
.counter {
  text-align: end;
  padding: 0 1rem;
  font-weight: 500;
}
.image_ct {
  display: flex;
  justify-content: center;
  align-items: center;
}
.image_main {
  cursor: pointer;
  display: flex;
  width: 80%;
  height: 80%;
  max-height: 200px;
  max-height: 200px;
  border-radius: 6px;
  box-shadow: 0 0 12px rgba(59, 59, 59, 0.149);
  background-color: rgb(255, 255, 255);
  overflow: hidden;
  justify-content: center;
  align-items: center;
}
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.input_file {
  display: none;
  opacity: 0;
  z-index: -90000;
}
.url_card {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}
.button_container {
  display: flex;
  justify-content: center;
}

.m_e_c {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999999;
  background-color: rgba(0, 0, 0, 0.418);
  justify-content: center;
  align-items: center;
  display: flex;
}

.e_c {
  overflow: scroll;
  border-radius: 6px;
  width: 700px;
  height: 80%;
  padding: 1rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
}

.order_card {
  padding-bottom: 0.3rem;
  border-bottom: 1px solid rgb(221, 221, 221);
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  display: flex;
  &:hover .product_title {
    color: rgb(8, 99, 210);
    text-decoration: underline;
  }
}
.image_container {
  height: 65px;
  width: 65px;
}
.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product_title {
  font-size: 0.9rem;
  color: rgb(35, 128, 241);
  font-weight: 600;
  transition: 0.3s;
}

.price {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1.5px;
  display: inline-block;
}
.quantity {
  @extend .price;
}

.details {
  margin-right: 4rem;
}

.order_list {
  margin-top: 2rem;
}

.b_a_c {
  margin-top: 2rem;
}
.h {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
.container {
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  padding: 0.5rem 0.8rem;
  background-color: rgb(241, 241, 241);
}
.b_a {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.b_a_email {
  @extend .b_a;
  align-items: flex-start;
}
.b_a_t {
  font-size: 0.9rem;
  font-weight: 500;
}
.data {
  font-size: 0.8rem;
  font-weight: 400;
  max-width: 500px;
  overflow: hidden;
  word-wrap: normal;
}

.status_container {
  margin-top: 2rem;
}
.status_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
}

.btn_c {
  align-items: center;
  margin-top: 5rem;
  display: flex;
  justify-content: space-between;
}
.btn {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background-color: rgb(8, 99, 210);
    color: white;
  }
}
.cancel {
  @extend .btn;
  background-color: rgb(255, 0, 0);
  color: white;
  &:hover {
    background-color: rgb(255, 0, 0);
    color: white;
  }
}
.notify {
  @extend .btn;
  background-color: rgb(10, 175, 13);
  color: white;
  &:hover {
    background-color: rgb(10, 175, 13);
    color: white;
  }
}
.header {
  justify-content: space-between;
  display: flex;
}
.user_info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.userId {
  font-size: 0.8rem;
  font-weight: 500;
  color: rgb(135, 135, 135);
}
.user_position {
  font-size: 0.8rem;
  font-weight: 500;
  color: rgb(8, 99, 210);
}

// Status
.select {
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0.2rem 0.5rem;
  width: min-content;
  border-radius: 6px;
}
.pending {
  @extend .container;
  background-color: #fff8e1;
  color: #ffc107;
}
.confirmed {
  @extend .container;
  background-color: #e8f5e9;
  color: #28a745;
}
.cancelled {
  @extend .container;
  background-color: #f8e6e8;
  color: #dc3545;
}
.shipped {
  @extend .container;
  background-color: #e3f2fd;
  color: #007bff;
}
.received {
  @extend .container;
  background-color: #e8f5e9;
  color: #4caf50;
}
.returned {
  @extend .container;
  background-color: #ffe0b2;
  color: #ff5722;
}
.refunded {
  @extend .container;
  background-color: #f8f9fa;
  color: #6c757d;
}

.conf_message {
  width: 100%;
  text-align: center;
}

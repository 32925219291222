@use "../../../global/constants.scss";
section {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: min-content;
}

.header {
  flex: 1;
  display: flex;
  justify-content: space-between;

  margin: 20px;
  .title {
    font-size: 24px;
    font-weight: 600;
  }
  .btn {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: constants.$admin-main-color;
    border-radius: 5px;

    cursor: pointer;
    p {
      color: white;
      margin-right: 5px;
    }
  }
}

// <div className={styles.news_list}>
//         <div className={styles.news_item}>
//           <div className={styles.news_title}>
//             <h3>News Title</h3>
//           </div>
//           <div className={styles.news_date}>
//             <p>2021-09-01</p>
//           </div>
//           <div className={styles.news_btn}>
//             <p>Edit</p>
//             <p>Delete</p>
//           </div>
//         </div>

.news_list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px;
}

.main_overlay {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

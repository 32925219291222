#main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.text {
  font-size: 1em;
  font-weight: 400;
  letter-spacing: 0.2rem;
  max-width: 500px;
  text-align: center;
}
.error_code {
  font-size: 5rem;
}
.referral {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  a {
    font-size: 0.9rem;
    letter-spacing: 0.2px;
    font-weight: 700;
  }
}
.arrow {
  transform: rotate(-90deg);
}

.mail_chimp {
  input {
    // display: ;
  }
}

@use "../../global//constants.scss";

.strong_news {
  padding: 0 15%;
  display: flex;
  flex-direction: row;
  height: 500px;
  width: 100%;
  gap: 16px;
  margin-top: 3rem;
  .side_image {
    flex: 1;
    height: 500px;
    background-color: rgb(200, 200, 200);
    border-radius: 8px;
    @media (max-width: 750px) {
      display: none;
    }
  }
  .content {
    flex: 5;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .image {
    width: 100%;
    height: 300px;
    background-color: grey;
    border-radius: 8px;
  }
  .title {
    font-size: 2rem;
    font-weight: 500;
  }
  .description {
    font-size: 0.9rem;
    margin-left: 0.5rem;
    text-align: justify;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .article_info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .category,
    .cta {
      font-size: 0.7rem;
      font-weight: 600;
    }
    .cta {
      color: constants.$primary-color;
      cursor: pointer;
      &:hover {
        color: #1466cb;
      }
    }
    .category {
      color: grey;
      margin-left: 0.5rem;
      font-style: italic;
    }
  }

  @media (max-width: 1420px) {
    padding: 0 10%;
  }
  @media (max-width: 1000px) {
    padding: 0 5%;
  }
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

@use "../../global/constants.scss";

#section:first-of-type {
  padding: 0;
}
.padding {
  padding: 0 10%;
}
#section {
  margin-top: 0;
  margin-bottom: 1rem;
  @extend .padding;
  &:first-of-type {
    margin-top: 65px;
  }
}
#brands {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text_part {
  @extend .padding;

  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  gap: 4rem;
}
.heading {
  letter-spacing: 1px;
  font-size: 2.3rem;
  font-weight: 500;
}
.grey {
  color: constants.$discount-color;
}
.sub_heading {
  letter-spacing: 0.3px;
  font-size: 1.2rem;
  font-weight: 400;
}

.base_text {
  font-size: 0.9rem;
  // max-width: 400px;
}

.image_container {
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.image {
  height: auto;
  width: inherit;
}

.divider {
  margin: 2rem 10%;
  border-width: 2px;
}
.limited {
  width: 300px;
}
.part {
  display: flex;
  > div {
    width: 50%;
  }
  &:first-of-type {
    margin-bottom: 5rem;
  }
}
.content {
  margin-top: 3rem;
  color: rgb(83, 83, 83);
  font-weight: 300;
}
.employee_profile {
  display: flex;
  gap: 1rem;
}
.circular_image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: grey;
}
.employee_info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .heading {
    font-size: 0.85rem;
    letter-spacing: 0;
    font-weight: 500;
  }
  .base_text {
    font-size: 0.8rem;
  }
}
.quote {
  font-size: 3rem;
  font-weight: 400;
  letter-spacing: -2px;
}
.portfolio {
  display: flex;
  gap: 1rem;
  // margin-top: 2rem;
}
.portfolio_item {
  flex: 1;
  .heading {
    font-size: 4rem;
    font-weight: 500;
    letter-spacing: 0;
  }
}

.clients {
  text-align: center;

  margin-top: 8rem;
  .heading {
    margin-bottom: 3rem;
    font-size: 2.8rem;
    font-weight: 500;
    letter-spacing: 1px;
  }
}
.clients_grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 0.5rem;
}
.client_item {
  height: 250px;
  background-color: grey;
}
.brands_header {
  width: 100%;
}
.brand_container {
  display: flex;
}

.numbers {
  @extend .heading;
  color: constants.$primary-color;
}

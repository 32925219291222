@use "../../../global/constants.scss";

.main {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: white;
  padding-left: 2rem;
  padding-top: 4rem;
  margin-bottom: 5rem;
  overflow-y: auto;
}

.list {
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  list-style: none;
  flex-direction: column;
  gap: 1rem;
}

.list_item {
  cursor: pointer;
  width: 100%;
  color: constants.$primary-color;
  font-weight: 600;
  font-size: 1.75rem;
  transition: 0.3s;
  &:hover {
    opacity: 0.7;
  }
}

.list_item_s {
  list-style: disc;
  margin-top: 0.9rem;
  margin-left: 0.5rem;
  font-size: 1rem;
  color: constants.$primary-color;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
    text-decoration: underline;
  }
}

.close_container {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

details summary {
  margin: 0 !important;
}

summary::-webkit-details-marker {
  display: none;
}

.main_card {
  height: 175px;
  display: flex;
  flex-direction: column;
}
.main_image {
  background-color: #d9d9d9;
  height: 125px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  overflow: hidden;
}
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.content {
  flex: 1;
  border: 1px solid #9e9e9e;

  border-radius: 6px;

  padding: 0.3rem 0.3rem;
  display: flex;

  flex-direction: column;
  justify-content: space-between;
}
.driver_name {
  color: #232526;
  font-size: 1.5rem;
  font-weight: 500;
}
.driver_description {
  color: #929292;
  font-size: 0.75rem;
  margin-top: 0.5rem;
  margin-left: 0.2rem;
  overflow: hidden;
  letter-spacing: -0.5px;
}
.driver_lower {
  padding: 0 0.3rem;
  hr {
    margin-top: 0.3rem;
  }
}
.version {
  font-size: 0.625rem;
  font-weight: 400;
}
.driver_os {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.os_icons {
  display: flex;
  gap: 0.5rem;
}
.os {
  margin-bottom: 2px;
}
.brand {
  font-size: 0.8rem;
  font-weight: 500;
  color: #232526;
}
@media (max-width: 1200px) {

  .content {
    padding: 0.2rem;
  }
  .driver_name {
    font-size: 1.2rem;
  }
  .driver_description {
    margin-top: 0.1rem;
  }
  .brand {
    font-size: 0.6rem;
  }
}

.container {
  z-index: 300;
  position: absolute;
  left: 15px;
  top: 0;
  display: inline-block;
  cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
  width: 20px;
  height: 2px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s;
}
.change {
  @extend .container;
}

/* Rotate first bar */
.change .bar1 {
  transform: translate(0, 8px) rotate(-45deg);
}

/* Fade out the second bar */
.change .bar2 {
  opacity: 0;
}

/* Rotate last bar */
.change .bar3 {
  transform: translate(0, -8px) rotate(45deg);
}

.pages {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;

  .selected {
    color: black;
    font-size: 1.1rem;
    font-weight: 500;
  }
  h3 {
    padding: 0;
    margin: 0;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
    color: rgb(127, 127, 127);
    &:hover {
      color: rgb(48, 48, 48);
    }
  }
}

#mainContainer {
  flex: 1;
  padding: 2rem 3rem;
  margin: 0;
  gap: 2rem;
  display: flex;
  flex-direction: column;
}
.notFound {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #999;
}

.cardList {
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
}
.adminCard {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}
.entry {
  user-select: none;
  flex: 1;
  div {
    cursor: not-allowed;
    overflow: hidden;
    background-color: #dedede;
  }
  input {
    background-color: #dedede;
    cursor: not-allowed;
    user-select: none;
  }
}

.search_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.dropDown {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
  height: 40px;
  border-radius: 6px;
  align-items: center;
  margin-bottom: 1rem;
  background-color: #dedede;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  font-weight: 400;
  border: 1px solid #373737;
}

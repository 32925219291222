@use "../../global/constants.scss";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.products {
  margin-top: 2rem;
  padding: 0;
}

.products .text-light {
  text-align: center;
  width: 70%;
  margin: 0.9rem auto;
}

.product_items {
  min-width: 330px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .noProducts {
    margin-top: 2rem;
    font-size: 1rem;
    font-weight: 500;
    font-style: italic;
    color: grey;
  }
}

#footer {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mainC {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
}

.f_pa_di {
  font-size: 0.8rem;
  font-weight: 600;
}

.btns {
  display: flex;
  gap: 1rem;
}

.button {
  cursor: pointer;
  outline: none;
  border: none;
  padding: 0.4rem 0.9rem;
  color: white;
  border-radius: 8px;
  transition: 0.3s;
  &:hover {
    opacity: 0.8;
  }
}

.next {
  @extend .button;
  background-color: constants.$admin-main-color;
}

.prev {
  @extend .button;
  background-color: constants.$secondary-color;
}

@media (max-width: 1340px) {
  .product_items {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 950px) {
  .product_items {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 560px) {
  .product_items {
    grid-template-columns: repeat(2, 1fr);
  }
}

// @media screen and (min-width: 300px) {
//   .product_items {
//     display: grid;
//     grid-template-columns: repeat(2, 1fr);
//   }
// }
// @media screen and (min-width: 700px) {
//   .product_items {
//     display: grid;
//     grid-template-columns: repeat(3, 1fr);
//   }
// }
// @media screen and (min-width: 1200px) {
//   .product_items {
//     grid-template-columns: repeat(4, 1fr);
//   }
//   .product {
//     margin-right: 1rem;
//     margin-left: 1rem;
//   }
//   .products .text_light {
//     width: 50%;
//   }
// }

// @media screen and (min-width: 1336px) {
//   .product_items {
//     grid-template-columns: repeat(5, 1fr);
//   }
// }

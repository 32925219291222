.container {
  display: flex;
  gap: 15px;
  position: absolute;
  flex-direction: column;
  bottom: 5%;
  right: 10px;
  z-index: 10000000;
  svg:hover {
    transform: scale(1.2);
    transition: all 0.3s ease-in-out;
  }
}
.notif {
  display: flex;
  flex-direction: column;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #01489a;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
  }
}

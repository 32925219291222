.main {
  padding: 0 6rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.drivers_list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 3rem;
  row-gap: 2rem;
}
@media (max-width: 1500px) {
  .drivers_list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1200px) {
  .main {
    padding: 0 2rem;
  }
}

@media (max-width: 1000px) {
  .drivers_list {
    gap: 1rem;
  }
}

@media (max-width: 850px) {
  .drivers_list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 635px) {
  .drivers_list {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 500px) {
  .main {
    padding: 0;
  }
}

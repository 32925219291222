.mail_item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  .indicator {
    margin-right: 1rem;
    margin-top: 0.5rem;
    height: 12px;
    width: 12px;
    border-radius: 1rem;
    background-color: #0b50b7;
  }
  .mail_content {
    max-width: 800px;
    height: 85px;
    .sender {
      font-size: 1rem;
      font-weight: 600;
    }
    .subject {
      font-size: 12px;
      font-weight: 400;
    }
    .description {
      @extend .subject;
      color: #878787;
    }
  }
  .date {
    display: flex;
    flex: 1;
    justify-content: flex-end;

    color: #626262;
    font-weight: 400;
  }
}

.pricing {
  margin-top: 1rem;
}

.pricing_button {
  color: rgba(0, 124, 226, 0.827);
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    color: rgb(0, 124, 226);
  }
}
.functions {
  display: flex;
  div {
    flex: 1;
  }
}

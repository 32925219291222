@use "../../../../global/constants.scss";
.mainContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.mainImage {
  display: flex;
  flex-direction: row;
  flex: 7;
  width: 500px;
  height: 500px;

  justify-content: center;
  align-items: flex-start;
  .noImage {
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.sideImages {
  display: flex;
  justify-content: center;
  flex: 1;

  height: 100%;
}
.sideImage {
  height: 100px;
  width: 100px;
  // border: ;
}
.image {
  flex: 4;
  height: 100%;

  display: flex;
  justify-content: center;

  img {
    object-fit: scale-down;
    width: auto;
    max-height: 100%;
  }
}

.imageInput {
  display: none;
  position: absolute;
  opacity: 0;
  height: 0px;
  width: 0px;
  z-index: -19999;
}

.button {
  cursor: pointer;
  gap: 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px #0000000e;
  border: 1px solid constants.$admin-main-color;
  transition: 0.3s;
  &:hover {
    background-color: rgb(244, 244, 244);
  }
}

.buttonText {
  font-size: 1rem;
}

.image {
  width: inherit;
  height: inherit;
  position: relative;
}

.remove {
  position: absolute;
  top: -20px;
  right: -20px;
}

@use "../../../global/constants.scss";
.main {
  margin-top: 2.1875rem;
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  padding: 0 4rem;
  h3 {
    font-size: 1.5rem;
    margin: 0;
  }
  h4 {
    font-size: 1.25rem;
    margin: 0;
    font-weight: 600;
  }
  h5 {
    font-size: 1rem;
    margin: 22px 0;
    font-weight: 500;
  }
}

.header {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.media {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}
.review_main {
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
}
.review_text {
  font-size: 1rem;
  font-weight: 500;
}
.review {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .ratingNumber {
    color: black;
    font-size: 1.2rem;
    font-weight: 600;
  }
}
.qt_selector {
  display: flex;
}
.qt_button {
  cursor: pointer;
  padding: 0.3rem;
  border: 1px solid rgb(107, 107, 107);
  user-select: none;
}
.add {
  border-left: none;
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;
}
.remove {
  border-right: none;
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;
}
.qt_container {
  width: 50px;
  padding: 0.3rem 1rem;
  border: 1px solid rgb(172, 172, 172);
  text-align: center;
}
.order {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.add_btn {
  cursor: pointer;
  height: 100%;
  background-color: #184e92;
  display: flex;
  padding: 0 1rem;
  border-radius: 60px;
  color: white;
  align-items: center;
  transition: 0.3s;
  &:hover {
    background-color: #0b5ed7;
  }
}
.prices {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.discountContainer {
  padding: 0.2rem;

  background-color: constants.$discount_color;

  p {
    font-size: 1rem;
    font-weight: 600;
    color: white;
  }
}

#productInfo {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-top: 2.5rem;
  gap: 1rem;
  padding: 0 1rem;
}

.column {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: auto;
  justify-content: flex-start;
  align-items: flex-start;
}

.column_right {
  @extend .column;
  padding-left: 1rem;
}

.description {
  // padding: 4rem 4rem 0 0;
  margin-top: 20px;
  padding: 0;

  p {
    text-align: justify;
    padding-left: 0.2rem;
  }
}

.features {
  margin-top: 2rem;
}

.p_r {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: 1;
  width: 100%;
}

@media (max-width: 1150px) {
  .main {
    padding: 0 1rem;
  }
  .p_r {
    flex-direction: column;
    gap: 2rem;
  }
}
@media (max-width: 1050px) {
  .main {
    padding: 0;
  }
  .description {
    padding: 1.5rem 1.5rem 0 0;
  }
}

.list {
  margin-top: 20px;
  padding-left: 1rem;
}
.list_item {
  margin: 0;
  margin-bottom: 1rem;
  padding: 0;
  color: #5c5c5c;
}

.warranty {
  margin-top: 24px !important;
}

.basePrice {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  p {
    font-weight: 500;
    color: #484848;
  }
  input {
    font-size: 1.375rem;
    font-weight: 500;
    color: #484848;
  }
}

.adaptivePrice {
  width: 100%;
}

.categoryContainer {
  margin-top: 1rem;
  gap: 0.7rem;
  display: flex;
  flex-direction: row;
  p {
    font-size: 1rem;
    font-weight: 500;
    color: #787878;
  }
  .category {
    cursor: pointer;
    font-weight: 400;
    color: #1d7eef;
    transition: 0.2s;
    &:hover {
      color: #5fa4f4;
    }
  }
}

.confirmation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  gap: 1rem;
  height: 20px;

  svg {
    fill: green;
  }
  p {
    color: green;
  }
}

@media (max-width: 850px) {
  #productInfo {
    flex-direction: column;
  }
  .description {
    padding: 0.5rem 0.5rem 0 0;
  }
  .column_right {
    padding-left: 0;
  }
}

.not_available_message {
  font-weight: 500;
  color: red;
  font-style: italic;
}
